<template>
  <transition name="modals">
    <Teleport v-if="modalOpen" to="body">
      <div
        :class="[
          twMerge(
            'fixed left-0 top-0 z-50 flex h-full w-[100vw] flex-col items-center justify-center bg-black/30 p-4 md:px-16 content-visibility backdrop-blur',
            modalOverlayClasses,
          ),
        ]"
        @keydown="handleKeyDown"
        @click.self="closeModal"
      >
        <div
          :data-testid="dataTestId"
          class="container-modal-common"
          :class="[
            twMerge(
              'relative size-full rounded-xl bg-s-50 px-4 pt-12 pb-12 shadow-dp-01 overflow-hidden',
              modalClasses,
            ),
          ]"
        >
          <slot name="closeButton">
            <button
              aria-label="Close modal"
              :class="[
                twMerge(
                  'bg-white p-1.5 rounded-lg outline-none absolute top-5 right-5 z-10 border border-white hover:border-p-500 hover:bg-p-100 transition-colors duration-200',
                  buttonCloseClasses,
                ),
              ]"
              @click.prevent="closeModal"
            >
              <IconClose class="size-5" />
            </button>
          </slot>

          <header
            v-if="$slots.header || title"
            :class="[twMerge('relative px-16 w-full', headerClasses)]"
          >
            <slot name="header" />
            <div
              v-if="title"
              class="w-full text-center text-lg font-semibold leading-7"
            >
              {{ title }}
            </div>
          </header>

          <div :class="[twMerge('h-full overflow-y-auto', contentClasses)]">
            <slot />
          </div>

          <slot name="footer" />
        </div>
      </div>
    </Teleport>
  </transition>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import IconClose from '~/assets/icons/Close.svg'

const emit = defineEmits(['close'])

const props = withDefaults(
  defineProps<{
    name: string
    isOpen?: boolean
    modalOverlayClasses?: string
    modalClasses?: string
    contentClasses?: string
    headerClasses?: string
    buttonCloseClasses?: string
    title?: string
  }>(),
  {
    modalOverlayClasses: '',
    modalClasses: '',
    contentClasses: '',
    headerClasses: '',
    buttonCloseClasses: '',
    title: '',
    isOpen: false,
  },
)

const attrs = useAttrs()

const modalOpen = computed(() => props.isOpen)
const dataTestId = computed(() => attrs['data-testid'] || `${props.name}-modal`)

const closeModal = () => emit('close')

const handleKeyDown = (event) => {
  if (event.key === 'Escape') closeModal()
}

watch(
  () => props.isOpen,
  (isOpen) => {
    if (import.meta.server) return

    const action = isOpen ? 'add' : 'remove'

    document.body.classList[action]('container-app')
  },
  { immediate: true },
)

onMounted(() => {
  document.addEventListener('keyup', handleKeyDown)
})

onUnmounted(() => {
  document.removeEventListener('keyup', handleKeyDown)
  document.body.classList.remove('container-app')
})
</script>

<style>
.modals-enter-active,
.modals-leave-active {
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.modals-enter-from,
.modals-leave-to {
  opacity: 0;
}
</style>
